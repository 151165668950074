var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppRow',{attrs:{"only-owner":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-col',{staticClass:"text-center text-h5",attrs:{"cols":"12"}},[_vm._v(" Лог действий ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-iterator',{attrs:{"items":_vm.logs,"items-per-page":5},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-expansion-panels',{staticClass:"rounded-lg",attrs:{"flat":"","focusable":""}},_vm._l((props.items),function(item){return _c('v-expansion-panel',{key:item.id},[_c('v-expansion-panel-header',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_vm._v(" "+_vm._s(item.actionType)+" ")]),_c('v-col',{staticClass:"text--secondary",attrs:{"cols":"12","sm":"8"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.captions),function(caption){return _c('v-col',{key:caption,attrs:{"cols":"12","sm":"4"}},[(caption === 'time')?[_vm._v(" "+_vm._s(_vm._f("moment")(item.data[caption],'DD.MM.YYYY, HH:mm'))+" ")]:[_vm._v(" "+_vm._s(item[caption])+" ")]],2)}),1)],1)],1)],1),_c('v-expansion-panel-content',[_c('v-list',{attrs:{"dense":""}},_vm._l((item.data),function(value,key){return _c('v-list-item',{key:key},[_c('v-list-item-content',{staticClass:"d-inline-block text-truncate"},[_vm._v(" "+_vm._s(key)+": ")]),(key === 'time')?_c('v-list-item-content',{staticClass:"d-inline-block text-truncate"},[_vm._v(" "+_vm._s(_vm._f("moment")(value,'DD.MM.YYYY, HH:mm'))+" ")]):_c('v-list-item-content',{staticClass:"d-inline-block text-truncate"},[_vm._v(" "+_vm._s(value)+" ")])],1)}),1)],1)],1)}),1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ApolloQuery',{attrs:{"query":require('../graphql/queries/getAllAdminUsers.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var adminsError = ref_result.error;
var adminsData = ref_result.data;
var adminsIsLoading = ref.isLoading;
return [(adminsError)?_c('AppNotification',{attrs:{"message":adminsError.message}}):(adminsData || adminsIsLoading === 1)?_c('v-card',{staticClass:"px-4 pb-3",attrs:{"loading":adminsIsLoading === 1,"flat":""}},[(adminsData)?[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_vm._v(" Админы: ")]),_c('v-col',{staticClass:"text-right"},[_c('ApolloMutation',{attrs:{"mutation":require(_vm.editedIndex === -1 ? '../graphql/mutations/createAdminUser.gql' : '../graphql/mutations/updateAdminUser.gql'),"update":_vm.updateCache,"variables":{
                        id: _vm.editedAdmin.id,
                        username: _vm.editedAdmin.username,
                        password: _vm.editedAdmin.password,
                        role: _vm.editedAdmin.role,
                        permissions: _vm.editedAdmin.permissions,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var mutate = ref.mutate;
                      var loading = ref.loading;
                      var createOrEditError = ref.error;
return [_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none",attrs:{"color":"primary","depressed":"","height":"36","large":""}},'v-btn',attrs,false),on),[_vm._v(" Создать ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":loading}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"Имя"},model:{value:(_vm.editedAdmin.username),callback:function ($$v) {_vm.$set(_vm.editedAdmin, "username", $$v)},expression:"editedAdmin.username"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"hide-details":"auto","label":"Пароль"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.editedAdmin.password),callback:function ($$v) {_vm.$set(_vm.editedAdmin, "password", $$v)},expression:"editedAdmin.password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.roles,"clearable":"","hide-details":"auto","label":"Роль"},model:{value:(_vm.editedAdmin.role),callback:function ($$v) {_vm.$set(_vm.editedAdmin, "role", $$v)},expression:"editedAdmin.role"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.permissionsItems,"clearable":"","hide-details":"auto","label":"Права","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.editedAdmin.permissions.length - 1)+" других) ")]):_vm._e()]}}],null,true),model:{value:(_vm.editedAdmin.permissions),callback:function ($$v) {_vm.$set(_vm.editedAdmin, "permissions", $$v)},expression:"editedAdmin.permissions"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return mutate()}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1)],1)],1),(createOrEditError)?_c('AppNotification',{attrs:{"icon-disabled":true,"message":createOrEditError.message}}):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":adminsData.getAllAdminUsers,"items-per-page":5},scopedSlots:_vm._u([{key:"item.permissions",fn:function(ref){
                      var item = ref.item;
return [(item.permissions[0])?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.permissions[0]))])]):_c('span',[_vm._v(" "+_vm._s(item.role === 'SUPERUSER' ? 'Все' : 'Отсутствуют')+" ")]),(item.permissions[1])?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(item.permissions.length - 1)+" других) ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY, HH:mm'))+" ")]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"depressed":"","height":"36","large":""},on:{"click":function($event){return _vm.editAdmin(item)}}},[_vm._v(" Редактировать ")])]}}],null,true)})]:_vm._e()],2):_c('AppNotification',{attrs:{"message":"Нет результата :("}})]}}])})],1),_c('v-col',{staticClass:"text-center text-h5",attrs:{"cols":"12"}},[_vm._v(" Чат ")]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ApolloMutation',{attrs:{"mutation":require('../graphql/mutations/setChatAsDisabled.gql'),"variables":{
          disable: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var mutate = ref.mutate;
        var loading = ref.loading;
        var disableError = ref.error;
return [_c('v-btn',{staticClass:"text-none rounded-lg",attrs:{"loading":loading,"block":"","depressed":"","x-large":""},on:{"click":function($event){return mutate()}}},[_vm._v(" Выключить чат ")]),(disableError)?_c('AppNotification',{attrs:{"icon-disabled":true,"message":disableError.message}}):_vm._e()]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ApolloMutation',{attrs:{"mutation":require('../graphql/mutations/setChatAsDisabled.gql'),"variables":{
          disable: false,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var mutate = ref.mutate;
        var loading = ref.loading;
        var enableError = ref.error;
return [_c('v-btn',{staticClass:"text-none rounded-lg",attrs:{"loading":loading,"block":"","color":"primary","depressed":"","x-large":""},on:{"click":function($event){return mutate()}}},[_vm._v(" Включить чат ")]),(enableError)?_c('AppNotification',{attrs:{"icon-disabled":true,"message":enableError.message}}):_vm._e()]}}])})],1),_c('v-col',{staticClass:"text-center text-h5",attrs:{"cols":"12"}},[_vm._v(" Игровые режимы ")]),_c('v-col',[_c('ApolloQuery',{attrs:{"query":require('../graphql/queries/getActiveGames.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var ref_result = ref.result;
        var gamesError = ref_result.error;
        var gamesData = ref_result.data;
        var gamesIsLoading = ref.isLoading;
return [(gamesError)?_c('AppNotification',{attrs:{"message":gamesError.message}}):(gamesData || gamesIsLoading === 1)?_c('v-card',{staticClass:"px-4 pb-3",attrs:{"loading":gamesIsLoading === 1,"flat":""}},[(gamesData)?_c('v-data-table',{attrs:{"headers":_vm.headersGames,"items":gamesData.getActiveGames,"items-per-page":5},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY, HH:mm'))+" ")]}},{key:"item.active",fn:function(ref){
        var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"color":item.active ? '' : 'primary',"depressed":"","height":"36","large":""}},[_vm._v(" "+_vm._s(item.active ? 'Выключить' : 'Включить')+" ")])]}}],null,true)}):_vm._e()],1):_c('AppNotification',{attrs:{"message":"Нет результата :("}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }