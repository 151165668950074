<template>
  <AppRow only-owner>
    <template v-slot>
      <v-col
        class="text-center text-h5"
        cols="12"
      >
        Лог действий
      </v-col>

      <v-col cols="12">
        <v-data-iterator
          :items="logs"
          :items-per-page="5"
        >
          <template v-slot:default="props">
            <v-expansion-panels
              class="rounded-lg"
              flat
              focusable
            >
              <v-expansion-panel
                v-for="item of props.items"
                :key="item.id"
              >
                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      {{ item.actionType }}
                    </v-col>
                    <v-col
                      class="text--secondary"
                      cols="12"
                      sm="8"
                    >
                      <v-row no-gutters>
                        <v-col
                          v-for="caption of captions"
                          :key="caption"
                          cols="12"
                          sm="4"
                        >
                          <template v-if="caption === 'time'">
                            {{ item.data[caption] | moment('DD.MM.YYYY, HH:mm') }}
                          </template>
                          <template v-else>
                            {{ item[caption] }}
                          </template>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item
                      v-for="(value, key) of item.data"
                      :key="key"
                    >
                      <v-list-item-content class="d-inline-block text-truncate">
                        {{ key }}:
                      </v-list-item-content>
                      <v-list-item-content
                        v-if="key === 'time'"
                        class="d-inline-block text-truncate"
                      >
                        {{ value | moment('DD.MM.YYYY, HH:mm') }}
                      </v-list-item-content>
                      <v-list-item-content
                        v-else
                        class="d-inline-block text-truncate"
                      >
                        {{ value }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
        </v-data-iterator>
      </v-col>

      <v-col cols="12">
        <ApolloQuery :query="require('../graphql/queries/getAllAdminUsers.gql')">
          <template v-slot="{ result: { error: adminsError, data: adminsData }, isLoading: adminsIsLoading }">
            <!-- Error -->
            <AppNotification
              v-if="adminsError"
              :message="adminsError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="adminsData || adminsIsLoading === 1"
              :loading="adminsIsLoading === 1"
              class="px-4 pb-3"
              flat
            >
              <template v-if="adminsData">
                <v-card-title>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      Админы:
                    </v-col>
                    <v-col class="text-right">
                      <ApolloMutation
                        :mutation="require(editedIndex === -1 ? '../graphql/mutations/createAdminUser.gql' : '../graphql/mutations/updateAdminUser.gql')"
                        :update="updateCache"
                        :variables="{
                          id: editedAdmin.id,
                          username: editedAdmin.username,
                          password: editedAdmin.password,
                          role: editedAdmin.role,
                          permissions: editedAdmin.permissions,
                        }"
                      >
                        <template v-slot="{ mutate, loading, error: createOrEditError }">
                          <v-dialog
                            v-model="dialog"
                            max-width="500px"
                            persistent
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                class="text-none"
                                color="primary"
                                depressed
                                height="36"
                                large
                                v-on="on"
                              >
                                Создать
                              </v-btn>
                            </template>

                            <v-card :loading="loading">
                              <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-text-field
                                        v-model="editedAdmin.username"
                                        hide-details="auto"
                                        label="Имя"
                                      />
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-text-field
                                        v-model="editedAdmin.password"
                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show ? 'text' : 'password'"
                                        hide-details="auto"
                                        label="Пароль"
                                        @click:append="show = !show"
                                      />
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        v-model="editedAdmin.role"
                                        :items="roles"
                                        clearable
                                        hide-details="auto"
                                        label="Роль"
                                      />
                                    </v-col>
                                    <v-col cols="12">
                                      <v-select
                                        v-model="editedAdmin.permissions"
                                        :items="permissionsItems"
                                        clearable
                                        hide-details="auto"
                                        label="Права"
                                        multiple
                                      >
                                        <template v-slot:selection="{ item, index }">
                                          <v-chip v-if="index === 0">
                                            <span>{{ item }}</span>
                                          </v-chip>
                                          <span
                                            v-if="index === 1"
                                            class="grey--text text-caption"
                                          >
                                            (+{{ editedAdmin.permissions.length - 1 }} других)
                                          </span>
                                        </template>
                                      </v-select>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer />

                                <v-btn
                                  color="primary"
                                  text
                                  @click="close"
                                >
                                  Отмена
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="mutate()"
                                >
                                  {{ buttonText }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <AppNotification
                            v-if="createOrEditError"
                            :icon-disabled="true"
                            :message="createOrEditError.message"
                          />
                        </template>
                      </ApolloMutation>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="adminsData.getAllAdminUsers"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.permissions`]="{ item }">
                    <v-chip v-if="item.permissions[0]">
                      <span>{{ item.permissions[0] }}</span>
                    </v-chip>
                    <span v-else>
                      {{ item.role === 'SUPERUSER' ? 'Все' : 'Отсутствуют' }}
                    </span>
                    <span
                      v-if="item.permissions[1]"
                      class="grey--text text-caption"
                    >
                      (+{{ item.permissions.length - 1 }} других)
                    </span>
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      class="text-none"
                      depressed
                      height="36"
                      large
                      @click="editAdmin(item)"
                    >
                      Редактировать
                    </v-btn>
                  </template>
                </v-data-table>
              </template>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>

      <v-col
        class="text-center text-h5"
        cols="12"
      >
        Чат
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <ApolloMutation
          :mutation="require('../graphql/mutations/setChatAsDisabled.gql')"
          :variables="{
            disable: true,
          }"
        >
          <template v-slot="{ mutate, loading, error: disableError }">
            <v-btn
              :loading="loading"
              block
              class="text-none rounded-lg"
              depressed
              x-large
              @click="mutate()"
            >
              Выключить чат
            </v-btn>
            <AppNotification
              v-if="disableError"
              :icon-disabled="true"
              :message="disableError.message"
            />
          </template>
        </ApolloMutation>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <ApolloMutation
          :mutation="require('../graphql/mutations/setChatAsDisabled.gql')"
          :variables="{
            disable: false,
          }"
        >
          <template v-slot="{ mutate, loading, error: enableError }">
            <v-btn
              :loading="loading"
              block
              class="text-none rounded-lg"
              color="primary"
              depressed
              x-large
              @click="mutate()"
            >
              Включить чат
            </v-btn>
            <AppNotification
              v-if="enableError"
              :icon-disabled="true"
              :message="enableError.message"
            />
          </template>
        </ApolloMutation>
      </v-col>

      <v-col
        class="text-center text-h5"
        cols="12"
      >
        Игровые режимы
      </v-col>

      <v-col>
        <ApolloQuery :query="require('../graphql/queries/getActiveGames.gql')">
          <template v-slot="{ result: { error: gamesError, data: gamesData }, isLoading: gamesIsLoading }">
            <!-- Error -->
            <AppNotification
              v-if="gamesError"
              :message="gamesError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="gamesData || gamesIsLoading === 1"
              :loading="gamesIsLoading === 1"
              class="px-4 pb-3"
              flat
            >
              <v-data-table
                v-if="gamesData"
                :headers="headersGames"
                :items="gamesData.getActiveGames"
                :items-per-page="5"
              >
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                </template>

                <template v-slot:[`item.active`]="{ item }">
                  <v-simple-checkbox
                    v-model="item.active"
                    disabled
                  />
                </template>

                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    :color="item.active ? '' : 'primary'"
                    class="text-none"
                    depressed
                    height="36"
                    large
                  >
                    {{ item.active ? 'Выключить' : 'Включить' }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import AppNotification from '@/components/AppNotification.vue';
import adminsQuery from '@/graphql/queries/getAllAdminUsers.gql';

export default {
  components: {
    AppRow,
    AppNotification,
  },
  data() {
    return {
      logs: [
        {
          id: 1,
          actionType: 'Редактирование пользователя',
          adminUsername: 'Admin',
          adminIp: '192.168.1.3',
          data: {
            userId: 445,
            balance: 1000,
            time: 1604833422,
          },
        },
        {
          id: 2,
          actionType: 'Удаление пользователя',
          adminUsername: 'Test',
          adminIp: '192.168.1.3',
          data: {
            userId: 2,
            time: 1604833100,
          },
        },
      ],
      captions: ['adminUsername', 'adminIp', 'time'],
      dialog: false,
      editedIndex: -1,
      editedAdmin: {},
      show: false,
      roles: ['ROLE_UNDEFINED', 'USER', 'ADMINISTRATOR', 'SUPERUSER', 'PARTNER', 'BLOGGER', 'BANNED', 'PUBLIC'],
      permissionsItems: ['KYC_VIEW', 'KYC_VIEW_LIST', 'KYC_APPROVE', 'DASHBOARD_ADMINISTRATION', 'CHAT_ADMINISTRATION', 'BONUS_ADMINISTRATION', 'USERS_ADMINISTRATION', 'WITHDRAW_ADMINISTRATION', 'GAMES_ADMINISTRATION', 'CREATE_MANUAL_BONUS', 'UPDATE_USER_BALANCE', 'WITHDRAW_READ', 'ADD_M_DEPOSIT', 'PD_SUPPORT', 'DEPOSITS_READ'],
      headers: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Имя:', value: 'username', class: 'white--text text-subtitle-2' },
        { text: 'Роль:', value: 'role', class: 'white--text text-subtitle-2' },
        { text: 'Права:', value: 'permissions', class: 'white--text text-subtitle-2' },
        { text: 'Дата регистрации:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      headersGames: [
        { text: 'Режим:', value: 'gameMode', class: 'white--text text-subtitle-2' },
        { text: 'Дата создания:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: 'Активен:', value: 'active', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Создать админа' : 'Редактирование';
    },
    buttonText() {
      return this.editedIndex === -1 ? 'Создать' : 'Сохранить';
    },
  },
  methods: {
    updateCache(store, { data: { createAdminUser } }) {
      if (this.editedIndex === -1) {
        const query = {
          query: adminsQuery,
        };
        // Read the query from cache
        const data = store.readQuery(query);
        // Mutate cache result
        data.getAllAdminUsers.push(createAdminUser);
        // Write back to the cache
        store.writeQuery({
          ...query,
          data,
        });
      }

      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedAdmin = {};
      });
    },
    editAdmin(item) {
      this.editedIndex = +item.id;
      this.editedAdmin = { ...item };
      this.dialog = true;
    },
  },
};
</script>
